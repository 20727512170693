/* eslint-disable react/no-array-index-key */
import React from 'react'
import { useStaticQuery, graphql, Link } from 'gatsby'

import Plaatjie from '@ubo/plaatjie'

// Elements
import ParseContent from 'components/shared/ParseContent'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

// Third Party
import styled from 'styled-components'

const Thumbnail = styled(Plaatjie)`
  height: 200px;
`

interface AllVacanciesProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_AllVacancies
}

const AllVacancies: React.FC<AllVacanciesProps> = ({ fields }) => {
  const query = useStaticQuery<GatsbyTypes.getAllVacanciesQuery>(graphql`
    query getAllVacancies {
      allWpVacancy {
        nodes {
          link
          title

          vacancyFields {
            excerpt
          }
          flexContent {
            banner {
              title
              subtitle
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 500)
                  }
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <section className="items">
      <div className="row">
        {query.allWpVacancy.nodes.map((it, index) => (
          <div className="col-lg-4 col-md-6 mb-4" key={index}>
            <div className="bg-grey p-4">
              <Link to={it.link || '/'}>
                <Thumbnail image={it.flexContent?.banner?.image} alt="" />
              </Link>
              <div className="mt-4">
                <h4>{it.title}</h4>
                <div>
                  <ParseContent
                    className="mb-4"
                    content={it.vacancyFields?.excerpt || ''}
                  />

                  <ButtonPrimary to={it.link || ''}>
                    {fields.buttonText}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </section>
  )
}

export default AllVacancies
